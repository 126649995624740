
import { Options, Vue } from 'vue-class-component';
import LoginLayout from '@/lib/layouts/LoginLayout.vue';
import BaseAlert from '@/lib/components/Banner/BaseAlert.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import dayjs, { Dayjs } from 'dayjs';
import { UserService } from '@/services/api';
import { useSessionStore } from '@/stores/session.store';

@Options({
  components: {
    ButtonLink,
    BaseButton,
    BaseAlert,
    LoginLayout
  },
  props: {
    enforceDate: {
      default: null,
      type: String
    },
    email: {
      default: null,
      type: String
    },
    forceReset: {
      default: false,
      type: Boolean
    }
  }
})
export default class WeakPasswordPage extends Vue {
  userService = new UserService();
  sessionStore = useSessionStore();
  loading = false;
  passwordResetSent = false;

  enforceDate!: string;
  email!: string | null;
  forceReset!: boolean;

  get redirect(): string {
    return this.sessionStore.currentUser?.is_admin ? 'domain-admin' : 'patient-list';
  }

  toDate(value: string): Dayjs {
    return dayjs.utc(value).local();
  }

  async requestResetPassword() {
    this.loading = true;

    await this.userService.requestResetPasswordEmail({
      email: this.email ?? ''
    });

    this.passwordResetSent = true;

    this.loading = false;
  }
}
